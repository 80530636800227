/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
// import Nebular Theme System and the corporate theme
@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/corporate';
@use '@nebular/theme/styles/themes/dark';

// Color variables
$primary: #003c5f;
$active-state: #f09236;
$dark-active-state: #d64200;
$info: #47a50f;
$primary-blue: #244b94;
$input-border-color: #ced4da;
$purple: #683c89;
$text: #212529;
$text-alt: #555;
$white: #fff;
$error: red;
$error-color: #ed5565;
$danger: #bc0210;
$dark-green: #048162;
$light-blue: #007db8;
$font-stack: 'Verdana, Geneva, sans-serif';
$color-arr-orange: #ff8c00;
$color-arr-orange-hover: #da6f27;
$color-arr-light-blue: #31ade8;
$color-arr-light-blue-hover: #2091bd;
$color-arr-yellow: #fb0;
$color-arr-yellow-hover: #d79d29;
$color-arr-dark-blue: #003c5f;
$color-arr-dark-blue-hover: #042b3a;
$enrolled-status-color: #006837;
$enrolled-status-border-color: #009146;
$enrolled-status-background-color: #bdd3a3;
$published-status-color: #46642a;
$published-status-border-color: #86b855;
$published-status-background-color: #b9d69d;
$declined-status-color: #a9897a;
$declined-status-border-color: #d1c0ba;
$declined-status-background-color: #e6dcda;
$not-enrolled-status-color: #7e6823;
$not-enrolled-status-border-color: #e4bc49;
$not-enrolled-status-background-color: #efd98b;
$pending-approval-status-color: #332c92;
$pending-approval-status-border-color: #726cd2;
$pending-approval-status-background-color: #bdbaea;
$cancelled-status-color: #920404b8;
$cancelled-status-border-color: #92040482;
$cancelled-status-background-color: #ff00003b;
$pending-withdrawal-status-color: #9d2805fa;
$pending-withdrawal-status-border-color: #fb4d13ab;
$pending-withdrawal-status-background-color: #fb4d1352;
$pending-validation-status-color: #204673;
$pending-validation-status-border-color: #4783cc;
$pending-validation-status-background-color: #97b9e2;
$open-status-color: #808080;
$missed-milestone: #c10000;
$survey-text-color: grey;

// create custom default theme
$nb-themes: nb-register-theme(
  (
    // Override colors here
    color-success-focus: $active-state,
    color-success-default: $primary,
    color-success-hover: $active-state,
    color-success-active: $active-state,
    color-info-focus: $primary-blue,
    color-info-default: $primary-blue,
    color-info-hover: #006fd6,
    color-info-active: #006fd6,
    color-info-disabled: rgba(143, 155, 179, 0.24),
    color-info-focus-border: $primary-blue,
    color-info-hover-border: $primary-blue,
    color-info-hovercolor-info-default-border: #0095ff,
    color-info-defaultcolor-info-active-border: #006fd6,
    color-info-activecolor-info-disabled-border: rgba(143, 155, 179, 0.24),
    color-info-transparent-focus: rgba(0, 149, 255, 0.24),
    color-info-transparent-hover: rgba(0, 149, 255, 0.16),
    color-info-transparent-default: rgba(0, 149, 255, 0.08),
    color-info-transparent-active: rgba(0, 149, 255, 0.24),
    color-info-transparent-disabled: rgba(143, 155, 179, 0.16),
    color-info-transparent-focus-border: #0095ff,
    color-info-transparent-hover-border: #0095ff,
    color-info-transparent-default-border: #0095ff,
    color-info-transparent-active-border: #0095ff,
    color-info-transparent-disabled-border: rgba(143, 155, 179, 0.24),
    color-danger-focus: #db2c66,
    color-danger-hover: #ff708d,
    color-danger-default: $error,
    color-danger-active: #db2c66,
    color-danger-disabled: rgba(143, 155, 179, 0.24),
    color-danger-focus-border: #b81d5b,
    color-danger-hover-border: #ff708d,
    color-danger-default-border: #ff3d71,
    color-danger-active-border: #db2c66,
    color-danger-disabled-border: rgba(143, 155, 179, 0.24),
    color-danger-transparent-focus: rgba(255, 61, 113, 0.24),
    color-danger-transparent-hover: rgba(255, 61, 113, 0.16),
    color-danger-transparent-default: rgba(255, 61, 113, 0.08),
    color-danger-transparent-active: rgba(255, 61, 113, 0.24),
    color-danger-transparent-disabled: rgba(143, 155, 179, 0.16),
    color-danger-transparent-focus-border: #ff3d71,
    color-danger-transparent-hover-border: #ff3d71,
    color-danger-transparent-default-border: #ff3d71,
    color-danger-transparent-active-border: #ff3d71,
    color-danger-transparent-disabled-border: rgba(143, 155, 179, 0.24),
    text-basic-color: $primary,
    font-family-primary: $font-stack,
    font-family-secondary: $font-stack,
    text-heading-1-font-family: $font-stack,
    text-heading-1-font-size: 2rem,
    text-heading-1-font-weight: 600,
    text-heading-1-line-height: 1.25,
    text-heading-2-font-family: $font-stack,
    text-heading-2-font-size: 1.25rem,
    text-heading-2-font-weight: 600,
    text-heading-2-line-height: 1.25,
    text-heading-3-font-family: $font-stack,
    text-heading-3-font-size: 1.5rem,
    text-heading-3-font-weight: 500,
    text-heading-3-line-height: 1.25,
    text-heading-4-font-family: $font-stack,
    text-heading-4-font-size: 1.35rem,
    text-heading-4-font-weight: 400,
    text-heading-4-line-height: 1.25,
    text-heading-5-font-family: $font-stack,
    text-heading-5-font-size: 1.25rem,
    text-heading-5-font-weight: 400,
    text-heading-5-line-height: 1.25,
    text-heading-6-font-family: $font-stack,
    text-heading-6-font-size: 1.125rem,
    text-heading-6-font-weight: 400,
    text-heading-6-line-height: 1.25,
    text-subtitle-font-family: $font-stack,
    text-subtitle-2-font-family: $font-stack,
    text-paragraph-font-family: $font-stack,
    text-paragraph-font-size: 1rem,
    text-paragraph-2-font-family: $font-stack,
    text-label-font-family: $font-stack,
    text-caption-font-family: $font-stack,
    text-caption-2-font-family: $font-stack,
    text-button-font-family: $font-stack,
    card-header-text-font-family: $font-stack,
    card-header-text-font-size: 1.5rem,
    context-menu-shadow: 0.35rem 0.35rem 0 rgb(0 0 0 / 10%),
    modal-border-radius: 20px,
    accordion-item-text-font-family: $font-stack,
    accordion-header-text-color: $primary,
    accordion-header-text-font-family: $font-stack,
    button-filled-primary-background-color: $primary,
    button-filled-primary-hover-background-color: $active-state,
    button-filled-primary-focus-background-color: $active-state,
    button-filled-primary-active-background-color: $active-state,
    button-filled-primary-border-color: $primary,
    button-filled-primary-hover-border-color: $active-state,
    button-filled-primary-focus-border-color: $active-state,
    button-filled-primary-active-border-color: $active-state,
    button-filled-basic-background-color: $white,
    button-filled-basic-hover-background-color: $active-state,
    button-filled-basic-border-color: $primary,
    button-filled-basic-hover-border-color: $active-state,
    button-filled-basic-text-color: $primary,
    button-filled-info-background-color: $info,
    button-filled-info-border-color: $info,
    button-filled-info-hover-background-color: $active-state,
    button-filled-info-hover-border-color: $active-state,
    button-filled-border-width: 2px,
    button-filled-danger-background-color: $danger,
    button-filled-danger-hover-background-color: $danger,
    button-filled-danger-focus-background-color: $danger,
    button-filled-danger-active-background-color: $danger,
    button-filled-danger-border-color: $danger,
    button-filled-danger-hover-border-color: $danger,
    button-filled-danger-focus-border-color: $danger,
    button-filled-danger-active-border-color: $danger,
    button-outline-basic-background-color: $white,
    button-outline-basic-border-color: $primary,
    button-outline-basic-text-color: $primary,
    button-outline-basic-hover-background-color: $active-state,
    button-outline-basic-hover-border-color: $active-state,
    button-outline-basic-hover-text-color: $white,
    button-outline-basic-focus-background-color: $white,
    button-outline-basic-focus-border-color: $active-state,
    button-outline-basic-focus-text-color: $active-state,
    button-outline-basic-active-background-color: $white,
    button-outline-basic-active-border-color: $active-state,
    button-outline-basic-active-text-color: $active-state,
    button-outline-text-transform: none,
    button-outline-border-width: 2px,
    button-outline-danger-background-color: $white,
    button-outline-danger-hover-background-color: $white,
    button-outline-danger-focus-background-color: $white,
    button-outline-danger-active-background-color: $white,
    button-outline-danger-border-color: $danger,
    button-outline-danger-hover-border-color: $danger,
    button-outline-danger-active-border-color: $danger,
    button-outline-danger-focus-border-color: $danger,
    button-outline-danger-text-color: $danger,
    button-outline-danger-hover-text-color: $danger,
    button-outline-danger-active-text-color: $danger,
    button-outline-danger-focus-text-color: $danger,
    button-text-font-family: $font-stack,
    button-rectangle-border-radius: 10px,
    list-item-font-size: 0.9375rem,
    list-item-font-family: $font-stack,
    menu-text-font-family: $font-stack,
    menu-text-font-size: 0.8125rem,
    menu-text-font-weight: 600,
    menu-text-color: $primary,
    menu-item-hover-text-color: $dark-active-state,
    menu-item-active-text-color: $white,
    menu-item-active-background-color: #003c5f,
    menu-item-icon-color: $primary,
    menu-item-padding: 15px,
    input-basic-background-color: $white,
    input-basic-text-color: #495057,
    input-medium-text-font-weight: 400,
    input-medium-text-font-size: 1rem,
    input-large-padding: 1.25rem,
    input-large-text-font-weight: 400,
    tag-outline-primary-text-color: black,
    tag-outline-primary-hover-text-color: #212529,
    tag-outline-primary-border-color: grey,
    tag-outline-primary-hover-border-color: grey,
    tag-outline-primary-hover-background-color: rgba(135, 206, 235, 0.4),
    tag-outline-primary-selected-background-color: rgba(135, 206, 235, 0.4),
    tag-outline-primary-selected-border-color: grey,
    tag-outline-primary-selected-text-color: #212529,
    tag-outline-primary-active-border-color: grey,
    tag-outline-primary-active-text-color: grey,
    tag-outline-primary-background-color: $white,
    tag-outline-primary-active-background-color: rgba(135, 206, 235, 0.1882),
    tag-text-font-family: $font-stack,
    tag-text-transform: none,
    tag-medium-text-font-size: 0.75rem,
    tag-medium-padding: 0 0.9375rem,
    tooltip-padding: 1rem,
    tooltip-background-color: $white,
    tooltip-text-font-size: 0.9375rem,
    tooltip-text-font-family: $font-stack,
    tooltip-text-font-weight: 400,
    tooltip-text-color: #222b45,
    sidebar-width: 20rem,
    sidebar-width-compact: 5rem,
    tag-small-text-line-height: 1,
    tag-small-text-font-weight: 600,
    tag-small-close-offset: 0.25rem,
    tag-small-padding: 0.0625rem 1rem,
    layout-text-font-family: $font-stack,
    layout-text-line-height: 1.5,
    input-medium-placeholder-text-font-size: 0.9375rem,
    input-placeholder-text-font-family: $font-stack,
    progress-bar-success-background-color: #d5d7db,
    progress-bar-danger-background-color: $error,
    progress-bar-medium-height: 0.3rem,
    progress-bar-success-filled-background-color: $primary,
    toastr-danger-background-color: #bc0210,
    toastr-danger-border-color: #bc0210,
    toastr-icon-danger-color: #bc0210,
    toastr-destroyable-danger-hover-background-color: #b9474f,
    toastr-destroyable-danger-hover-border-color: #b9474f,
    toastr-destroyable-info-hover-background-color: #4b7ad3,
    toastr-destroyable-info-hover-border-color: #4b7ad3,
    select-outline-basic-border-color: $input-border-color,
    select-outline-basic-focus-border-color: $active-state,
    select-outline-basic-background-color: transparent,
    select-outline-border-width: 2px,
    select-outline-basic-hover-background-color: transparent,
    select-outline-basic-hover-border-color: $active-state,
    select-outline-medium-padding: 0.5rem 0.75rem,
    select-rectangle-border-radius: 5px,
    select-outline-basic-text-color: #495057,
    select-outline-basic-placeholder-text-color: #495057,
    select-text-font-family: $font-stack,
    select-medium-text-font-size: 1rem,
    select-medium-text-font-weight: 400,
    option-medium-text-font-weight: 400,
    input-medium-padding: 0.5rem,
    input-rectangle-border-radius: 5px,
    input-basic-border-color: $input-border-color,
    input-border-width: 2px,
    input-basic-hover-border-color: $active-state,
    input-basic-focus-border-color: $active-state,
    input-basic-hover-background-color: transparent,
    select-outline-large-padding: 1.25rem,
    option-selected-background-color: #4e8aff,
    actions-icon-color: $primary-blue,
    calendar-large-width: 100%,
    tabset-background-color: #fff,
    tabset-shadow: 0.35rem 0.35rem 0 rgb(0 0 0 / 10%),
    tabset-content-padding: 0,
    tabset-tab-active-text-color: $primary,
    tabset-tab-text-color: $light-blue,
    tabset-tab-active-underline-color: $primary,
    tabset-tab-hover-text-color: $primary,
    tabset-tab-hover-underline-color: $primary,
    tabset-tab-text-transform: none,
    footer-padding: 0.75rem
  ),
  pep,
  corporate
);

// create custom Dark theme
$nb-themes: nb-register-theme(
  (
    // Override colors here
    color-primary-100: #f2f6ff
  ),
  pep-dark,
  dark
);
