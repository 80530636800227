/* stylelint-disable no-descending-specificity */
/* stylelint-disable scss/at-mixin-argumentless-call-parentheses */
// this is our just created themes.scss file, make sure the path to the file is correct
@use 'themes' as *;

// framework component styles
@use '@nebular/theme/styles/globals' as *;
@use '@nebular/auth/styles/globals' as *;

@include nb-install() {
  @include nb-theme-global();
  @include nb-auth-global();
}

// PEP specific variables
:root {
  // trwe-auth overrides
  --trwe-login-logo: url('https://tgt-cdn.trwe.net/logos/apps/engage/engage-logo.svg');

  // pep-shared overrides
  --pes-app-header-toggle-button-color: $active-state;
  --pes-app-header-logo-small: url('https://tgt-cdn.trwe.net/logos/apps/engage/engage-logo_small.svg');
  --pes-app-header-logo-large: url('https://tgt-cdn.trwe.net/logos/apps/engage/engage-logo_horizontal.svg');
}

/* global setting for scrollbar */
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #cecece;
  cursor: pointer;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-track {
  background: #f7f9fc;
}

/* toast notification */
.toast-message {
  block-size: fit-content !important;
  width: min-content !important;
  min-width: 400px !important;
}

nb-toast .icon-container nb-icon {
  font-size: 1.5rem !important;
}

nb-toastr-container nb-toast.status-danger:hover {
  nb-icon {
    color: #b9474f !important;
  }
}

/* Sidebar */
nb-sidebar {
  color: $white;

  .main-container-fixed .scrollable {
    background-image: linear-gradient($light-blue, $primary);
    padding-top: 0 !important;
  }

  &.expanded {
    nb-menu .menu-item {
      &:last-child {
        border-bottom: 1px solid rgba(#000, 0.18) !important;
      }

      a {
        color: $white !important;
        font-size: 1.125rem !important;
        font-weight: 500 !important;
        border-left: 10px solid transparent;
        gap: 20px;

        nb-icon.menu-icon {
          margin: 0;
          opacity: 0.5;
          color: $white !important;
        }

        &:hover {
          background: rgba(#000, 0.13) !important;

          nb-icon.menu-icon {
            color: $white;
            opacity: 1;
          }
        }

        &.active {
          border-left-color: $active-state !important;
          background: rgba(#000, 0.18) !important;

          &::before {
            content: none !important;
          }

          nb-icon.menu-icon {
            color: $white;
            opacity: 1;
            width: 1.25rem;
          }
        }
      }
    }
  }

  &.compacted {
    nb-menu .menu-item {
      a {
        &::before {
          opacity: 0;
        }

        &:hover {
          background: rgba(#000, 0.13) !important;
        }

        nb-icon.menu-icon {
          color: $white !important;
        }
      }

      &:last-child {
        border-bottom: 1px solid rgba(#000, 0.18) !important;
      }
    }
  }
}

nb-tooltip {
  box-shadow: 0.35rem 0.35rem 0 rgb(0 0 0 / 10%) !important;
}

/* Cards */
nb-card {
  margin: 0;
  box-shadow: 0.35rem 0.35rem 0 rgb(0 0 0 / 10%);

  &:not(.dialog-card) {
    border-left: 1rem solid $primary;
  }

  &.color-arr-orange {
    border-left: 1rem solid $color-arr-orange;
  }

  &.color-arr-light-blue {
    border-left: 1rem solid $color-arr-light-blue;
  }

  &.color-arr-yellow {
    border-left: 1rem solid $color-arr-yellow;
  }

  &.color-arr-dark-blue {
    border-left: 1rem solid $color-arr-dark-blue;
  }

  nb-card-header {
    text-align: center !important;
    border-bottom: unset !important;
  }
}

nb-dialog-container {
  nb-card {
    max-height: 95vh;
    border-radius: 1rem;

    nb-card-body {
      padding: 2rem;
    }
  }
}

/* date picker (do not want purple border-left) */
nb-calendar nb-card {
  border: #e4e9f2 solid 0.0625rem !important;
}

/* inputs */
.search-box {
  position: relative;

  [nbInput]::placeholder {
    color: $primary !important;
    text-align: left;
  }

  .search-icon {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    color: $primary;
    font-weight: 600 !important;
  }

  .cancel-icon {
    position: absolute;
    height: 100%;
    font-weight: 600;
    right: 10px;
    color: #b2b2b2;
    top: 9px;
  }

  input,
  select {
    border: none;
    border-bottom: 1px solid #000064 !important;
    background: transparent !important;
    border-radius: 0 !important;
    padding: 0.4375rem 0 0.4375rem 2rem !important;
  }
}

nb-select button {
  min-width: 0 !important;

  &.placeholder {
    opacity: 1 !important;
  }
}

/* forms */
.required {
  color: red;
  padding: unset !important;
}

.form-group {
  position: relative;

  .invalid-feedback {
    text-align: left;
  }

  .form-label {
    z-index: 1;
    position: absolute;
    font-size: 0.8rem;
    top: -0.5rem;
    left: 0.8rem;
    padding: 0 0.5rem;
    font-weight: 600;
    background-color: $white;
  }
}

/* buttons */
[nbButton] {
  &.appearance-filled {
    text-transform: none !important;
    font-weight: 700 !important;

    &.size-large {
      padding: 0.8125rem 1.125rem !important;
    }

    &.size-medium.status-basic:hover {
      color: $white !important;
    }

    &.size-small {
      padding: 0.4375rem 0.875rem !important;
    }
  }

  &.cancel-btn {
    background-color: navy !important;
  }

  &.narrow-btn {
    padding: 0.25rem 0.65rem !important;
  }
}

/* header */
.navbar-toggler {
  border: none;

  &:focus {
    box-shadow: none;
  }
}

a.toggle {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $active-state;
  }

  nb-icon {
    font-size: 2rem;
  }
}

/* icons */
.icons {
  color: gray;
  padding: 0 0.5rem;
  cursor: pointer;

  &:hover {
    color: $active-state;
  }
}

.calendar-icon {
  color: $primary !important;
}

.tiny-icon {
  font-size: 14px;
}

/* tabset */
nb-tab {
  overflow: unset !important;
}

/* Tags */
nb-tag {
  &.size-small {
    font-size: 12px;
  }

  &.published {
    background-color: $published-status-background-color !important;
    border-color: $published-status-border-color !important;
    color: $published-status-color !important;
  }

  &.declined {
    background-color: $declined-status-background-color !important;
    border-color: $declined-status-border-color !important;
    color: $declined-status-color !important;
  }

  &.enrolled {
    background-color: $enrolled-status-background-color !important;
    border-color: $enrolled-status-border-color !important;
    color: $enrolled-status-color !important;
  }

  &.not-enrolled,
  &.draft {
    background-color: $not-enrolled-status-background-color !important;
    border-color: $not-enrolled-status-border-color !important;
    color: $not-enrolled-status-color !important;
  }

  &.pending-approval {
    background-color: $pending-approval-status-background-color !important;
    border-color: $pending-approval-status-border-color !important;
    color: $pending-approval-status-color !important;
  }

  &.cancelled {
    background-color: $cancelled-status-background-color !important;
    border-color: $cancelled-status-border-color !important;
    color: $cancelled-status-color !important;
  }

  &.pending-withdrawal {
    background-color: $pending-withdrawal-status-background-color !important;
    border-color: $pending-withdrawal-status-border-color !important;
    color: $pending-withdrawal-status-color !important;
  }

  &.pending {
    background-color: $pending-validation-status-background-color !important;
    border-color: $pending-validation-status-border-color !important;
    color: $pending-validation-status-color !important;
  }
}

/* Accordions */
nb-icon.expansion-indicator {
  left: 1rem !important;
  font-size: xx-large !important;
  color: grey;
}

nb-accordion-item:not(.patient-filter-acc) {
  background-color: transparent !important;

  nb-accordion-item-header.study-list-item {
    background-color: $white;

    &:hover,
    &.accordion-item-header-expanded {
      background-color: #e9e8e8 !important;
    }
  }

  &.with-table nb-accordion-item-body .item-body {
    padding: 0;
  }
}

.set-list {
  height: 30rem;
  overflow-y: auto;
  border: 1px solid #d2d7db;
  border-radius: 8px;

  legend {
    width: auto;
    font-size: 0.875rem;
    font-weight: 600;
    float: none;

    span {
      padding: 0 0.5rem;
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.svd_container.sv_default_css label p {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0;
  font-weight: 700;
  color: #6d7072;
  font-size: 14px;
  font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
