.sv-input.sv-tagbox:not(.sv-tagbox--empty):not(.sv-input--disabled) {
  height: auto;
  padding: 0.5em;
  padding-right: 2em;
}

.sv-tagbox_clean-button {
  height: 1.5em;
  padding: 0.5em;
  margin: auto 0;
}

.sv-tagbox__value.sv-dropdown__value {
  position: relative;
  gap: 0.25em;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-inline: unset;
  margin-inline: unset;
  margin-block: unset;
}

.sv-tagbox__item {
  position: relative;
  display: flex;
  color: $text-color;
  height: 1.5em;
  padding: 0.25em 0.4em 0.25em 0.87em;
  border: solid 0.1875em #9f9f9f;
  border-radius: 2px;
  min-width: 2.3125em;
}

.sv-tagbox__item:hover {
  background-color: $main-hover-color;
  color: $body-background-color;

  .sv-tagbox__item_clean-button-svg use {
    fill: $body-background-color;
  }
}

.sv-tagbox__item-text {
  color: inherit;
  font-size: 1em;
}

.sv-tagbox__item_clean-button-svg {
  margin: 0.3125em;
}
.sv-tagbox__item_clean-button-svg use {
  fill: $text-color;
}

.sv-tagbox__filter-string-input {
  width: auto;
  display: flex;
  flex-grow: 1;
  position: initial;
}
.sv-tagbox__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: left;
  cursor: text;
  pointer-events: none;
  color: $main-hover-color;
}
